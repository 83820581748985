import { Injectable } from '@angular/core';

import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../auth/auth.service';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LogedGuardService implements CanActivate{

  constructor(public authService: AuthService, public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{

    let accessToken = this.authService.getJWT();
    
    if(accessToken != null){
      return this.authService.isValidToken().pipe(
        map((r) => {
          this.router.navigate(['/home']);
          return false;
        }), 
        catchError(() => {
          return of(true);
        })
      );
    }else{
      return of(true);
    }
  }

}
