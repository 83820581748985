import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) {}

  get<T>(urlApi: String, endpoint: string, params?: { [param: string]: string | string[] }, reqOpts?: any): Observable<any> {

    if (!reqOpts)
      reqOpts = {};

    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if (!params[key]) {
            delete params[key];
          }
        }
      }
      reqOpts.params = params;
    }

    return this.http.get<T>(urlApi + endpoint, reqOpts);
  }

  post<T>(urlApi: String, endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post<T>(urlApi + endpoint, body, reqOpts);
  }

  put<T>(urlApi: String, endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put<T>(urlApi + endpoint, body, reqOpts);
  }

  delete<T>(urlApi: String, endpoint: string, params?: { [param: string]: string | string[] }, reqOpts?: any): Observable<any> {

    if (!reqOpts) 
      reqOpts = {};

    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if (!params[key]) {
            delete params[key];
          }
        }
      }
      reqOpts.params = params;
    }
    return this.http.delete<T>(urlApi + endpoint, reqOpts);
  }

  getOctetStream(urlApi: String, endpoint: string){
    return this.http.get(urlApi + endpoint, { responseType: 'arraybuffer' });
  }
  
}