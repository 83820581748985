import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NotLogedGuardService as NotLogedGuard } from './services/guards/not-loged/not-loged-guard.service';
import { LogedGuardService as LogedGuard } from './services/guards/loged/loged-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/public/login/login.module').then( m => m.LoginModule),
    canActivate: [LogedGuard] 
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/public/register/register.module').then( m => m.RegisterModule),
    canActivate: [LogedGuard] 
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/public/forgot/forgot.module').then( m => m.ForgotModule),
    canActivate: [LogedGuard] 
  },
  {
    path: 'verify/:jwt',
    loadChildren: () => import('./pages/public/verify/verify.module').then( m => m.VerifyModule),
    canActivate: [LogedGuard] 
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/private/home/home.module').then( m => m.HomeModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'policies',
    loadChildren: () => import('./pages/private/policies/policies.module').then( m => m.PoliciesModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'policy-details/:numPoliza',
    loadChildren: () => import('./pages/private/policies/policy-details/policy-details.module').then( m => m.PolicyDetailsModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'club-cover',
    loadChildren: () => import('./pages/private/club-cover/club-cover.module').then( m => m.ClubCoverModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/private/news/news.module').then( m => m.NewsModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/private/promotions/promotions.module').then( m => m.PromotionsModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'sinisters',
    loadChildren: () => import('./pages/private/sinisters/sinisters.module').then( m => m.SinistersModule),
    canActivate: [NotLogedGuard] 
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/private/contact/contact.module').then( m => m.ContactModule),
    canActivate: [NotLogedGuard] 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}